<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <span> <v-icon left>mdi-cog</v-icon> Settings</span>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="loading" color="success" rounded>
                            <v-icon left>mdi-home</v-icon>
                            <span v-if="storefront">Store Front</span>
                            <span v-else>Create Store Front</span>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class=" text-center">
                        <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
                        <v-form v-else>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="companyInfo.name"
                                        filled
                                        placeholder="Company Name"
                                    >
                                        <v-icon slot="prepend">mdi-domain</v-icon>
                                    </v-text-field>
                                </v-col>
<!--                                <v-col cols="12" md="6">-->
<!--                                    <v-text-field-->
<!--                                        v-model="companyInfo.owner"-->
<!--                                        filled-->
<!--                                        placeholder="Company Owner"-->
<!--                                    >-->
<!--                                        <v-icon slot="prepend">mdi-account</v-icon>-->
<!--                                    </v-text-field>-->
<!--                                </v-col>-->
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="companyInfo.phone"
                                        filled
                                        placeholder="Company Phone"
                                    >
                                        <v-icon slot="prepend">mdi-phone</v-icon>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="companyInfo.location"
                                        filled
                                        placeholder="Company Location"
                                    >
                                        <v-icon slot="prepend">mdi-map-marker</v-icon>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                                    <v-btn
                                        @click="updateCompany"
                                        :loading="updating"
                                        block
                                        color="success"
                                        rounded><v-icon left>mdi-sync</v-icon> Update</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Crud from '../../../gql/PrismaCrud.gql'
const Company = new Crud('company')

export default {
    name: "CompanySettings",
    data() {
        return {
            loading: true,
            updating: false,
            companyInfo: {},
            storefront: null,
            companyID: this.$store.getters['auth/getUserCompany']
        }
    },
    methods: {
        updateCompany(){
            this.updating = true
            let company = {...this.companyInfo}
            Company.update(`{id}`,{
                where: {id: this.companyID},
                data: company
            }).then(res=>{
                this.$message('Company updated successfully', '', 'mdi-check', 'success')
            }).catch(console.error)
            .finally(_=>{
                this.updating = false
            })
        }
    },
    created(){
        Company.findOne(`{
            name
            owner
            location
            phone
            storefront{
                id
            }
        }`, {
            where: {id: this.companyID}
        }).then(res=>{
            this.storefront = res.storefront
            delete res.storefront
            delete res.__typename
            this.companyInfo = res
        }).catch(err=>{
            console.log(err)
        }).finally(_=>{
            this.loading = false
        })
    }
}
</script>
